<template>
  <div class="container p-4 mb-4">
    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
      {{ error }}
    </b-alert>
    <b-container class="p-4 mb-4 usp-bgr mtk-shadow">
      <!-- Apollo watched Graphql query -->
      <ApolloQuery :query="require('../graphql/ListCategories.gql')">
        <template slot-scope="{ result: { loading, error, data } }">
          <!-- Loading -->
          <div v-if="loading" class="loading apollo">{{ $t('btnOptions.loading') }}</div>

          <!-- Error -->
          <div v-else-if="error" class="error apollo">{{ $t('btnOptions.error') }}</div>

          <!-- Result -->
          <div v-else-if="data" class="result apollo">
            <h3 class="text-dark">{{ $t('btnOptions.manageCategories') }}</h3>
            <b-input-group v-for="cat in data.categories" :key="cat.id" size="sm" class="mt-3">
              <b-form-input maxlength="25" class="p-1" v-model="cat.name"></b-form-input>
              <b-form-input maxlength="15" class="p-1" v-model="cat.slug"></b-form-input>
              <div class="mt-2 ml-3">
                <b-form-radio-group
                  v-model="cat.shop_type_id"
                  :options="shop_type_id_options"
                  class=""
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
              <b-input-group-append>
                <b-button @click="onSaveCat(cat)" variant="outline-success">{{
                  $t('btnOptions.save')
                }}</b-button>
                <b-button @click="onDelCat(cat)" variant="outline-danger">{{
                  $t('btnOptions.delete')
                }}</b-button>
                <b-button @click="onSaveCat(cat)" variant="outline-info">{{
                  $t('btnOptions.images')
                }}</b-button>
                <b-button @click="onSaveCat(cat)" variant="outline-primary">{{
                  $t('btnOptions.products')
                }}</b-button>
              </b-input-group-append>
            </b-input-group>

            <b-button-group class="mt-4">
              <b-button variant="outline-success" @click="data.categories.push({})">
                {{ $t('btnOptions.addCategory') }}
              </b-button>
            </b-button-group>
          </div>

          <!-- No result -->
          <div v-else class="no-result apollo">{{ $t('btnOptions.noResult') }}</div>
        </template>
      </ApolloQuery>
    </b-container>
  </div>
</template>

<script>
import QUERY_CAT from '@/graphql/ListCategories.gql';
import SAVE_CAT from '@/graphql/SaveCatName.gql';
import INS_CAT from '@/graphql/InsertCat.gql';
import DEL_CAT from '@/graphql/DelCat.gql';
export default {
  data() {
    return {
      error: null,
      showDismissibleAlert: false,
      shop_type_id_options: [
        { item: 1, name: 'A la Carte' },
        { item: 2, name: 'Cantine' },
      ],
    };
  },

  methods: {
    log(v) {
      console.log(v);
    },
    onDelCat(cat) {
      this.$apollo
        .mutate({
          mutation: DEL_CAT,
          variables: {
            id: cat.id,
          },
          update: (store, { data: { delete_categories_by_pk } }) => {
            const data = store.readQuery({ query: QUERY_CAT });
            const index = data.categories.findIndex((e) => e.id === delete_categories_by_pk.id);
            if (index > -1) {
              data.categories.splice(index, 1);
            }
            store.writeQuery({ query: QUERY_CAT, data });
          },
        })
        .catch((error) => {
          this.error = JSON.stringify(error.message);
          this.showDismissibleAlert = true;
          window.scrollTo(0, 0);
        });
    },
    onSaveCat(cat) {
      if (!cat.id) {
        this.$apollo.mutate({
          mutation: INS_CAT,
          variables: {
            name: cat.name,
            slug: cat.slug,
            shop_type_id: cat.shop_type_id,
          },
          update: (store, { data: { insert_categories_one } }) => {
            const data = store.readQuery({ query: QUERY_CAT });
            data.categories[data.categories.length - 1] = insert_categories_one;
            store.writeQuery({ query: QUERY_CAT, data });
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: SAVE_CAT,
          variables: {
            id: cat.id,
            name: cat.name,
            slug: cat.slug,
            shop_type_id: cat.shop_type_id,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .form-control {
    width: 30%;
  }
}
</style>
