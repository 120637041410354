<template>
  <shop-edit-categories />
</template>

<script>
import ShopEditCategories from '../../components/ShopEditCategories.vue';
export default {
  components: { ShopEditCategories },
};
</script>

<style></style>