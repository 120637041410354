<template>
  <div class="container p-4 mb-4">
    <h1>{{ $t('shopSection.contactTitle') }}</h1>
    <b-container class="my-4 py-2 mtk-shadow bg-white">
      <ApolloQuery :query="require('@/graphql/ListMessages.gql')">
        <ApolloSubscribeToMore
          :document="require('@/graphql/MessageAdded.gql')"
          :update-query="onMessageAdded"
        />

        <div slot-scope="{ result: { data } }">
          <template v-if="data">
            <b-col class="ml-0 mx-auto p-3" style="width: 450px;">
              <b-form-group
                :label="$t('searchInput.search')"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('searchInput.searchType')"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-table
              :items="dataForMsg"
              :fields="fields"
              striped
              responsive="sm"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(details)="row">
                <b-button-group>
                  <b-button variant="outline-info" size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? $t('btnOptions.hide') : $t('btnOptions.show') }}
                  </b-button>
                  <b-button variant="outline-danger" size="sm" @click="deleteMessage(row.item)">
                    {{ $t('btnOptions.delete') }}
                  </b-button>
                </b-button-group>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-1">
                    <div class="p-2">{{ row.item.message }}</div>
                  </b-row>
                  <b-button
                    variant="outline-info"
                    class="mt-1"
                    size="sm"
                    @click="row.toggleDetails"
                  >
                    {{ $t('btnOptions.hide') }}
                  </b-button>
                </b-card>
              </template>
            </b-table>
            <template>
              <div class="overflow-auto">
                <b-pagination
                  align="center"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
                <p class="mt-3">{{ $t('pagination.page') }} {{ currentPage }}</p>
              </div>
            </template>
          </template>
        </div>
      </ApolloQuery>
    </b-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import DEL_CONTACT_MSG from '@/graphql/DelContactMsg.gql';

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'created_at',
      dataForMsg: [],
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],

      fields: [
        {
          key: 'created_at',
          label: 'Geschickt am',
          sortable: true,

          formatter: (value) => {
            return dayjs(value)
              .locale('de')
              .format('YYYY/MMMM/DD - HH:mm');
          },
        },
        'name',
        'email',
        'details',
      ],
    };
  },
  computed: {
    rows() {
      return this.dataForMsg.length;
    },
  },
  methods: {
    onMessageAdded(previousResult, { subscriptionData }) {
      this.dataForMsg = subscriptionData.data.contact_messages;
      // console.log(subscriptionData.data.contact_messages);
      // console.log(previousResult);
      // console.log(subscriptionData);
      // const newResult = { orders: [...subscriptionData.data.orders] };
      // console.log(newResult);
      // return {orders: [{id: 1, user_id: 2, __typename: "orders", created_at: "sdgdsgsg"}]};
      // TODO: Make subscription to fetch only fresh stuff to make it scale better.
      return subscriptionData.data;
    },
    deleteMessage(item) {
      this.$apollo
        .mutate({
          mutation: DEL_CONTACT_MSG,
          variables: {
            id: item.id,
            email: item.email,
          },
        })
        .catch((error) => {
          this.error = JSON.stringify(error.message);
        });
    },
  },
};
</script>

<style>
.container {
  box-sizing: border-box;
}
</style>
