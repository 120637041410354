<template>
  <div class="container p-4 mb-4">
    <h1>{{ $t('nav.orders') }}</h1>
    <b-container class="my-4 py-2 mtk-shadow bg-white">
      <ApolloQuery :query="require('@/graphql/ListOrders.gql')">
        <ApolloSubscribeToMore
          :document="require('@/graphql/OrderAdded.gql')"
          :update-query="onOrderAdded"
        />

        <div slot-scope="{ result: { data } }">
          <b-col class="ml-0 mx-auto p-3" style="width: 450px">
            <b-form-group
              :label="$t('searchInput.search')"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  :placeholder="$t('searchInput.searchType')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="data">
            <b-table
              :items="dataForShowing"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              striped
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(details)="row">
                <b-button-group>
                  <div v-if="row.item.order_items.length > 1" @click="row.toggleDetails">
                    <img class="cart-plus" src="../../../public/cart-plus.png" />
                  </div>
                  <b-button variant="outline-info" size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? $t('btnOptions.hide') : $t('btnOptions.show') }}
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    @click="info(row.item, row.index, $event.target)"
                    class="mr-0"
                  >
                    {{ $t('btnOptions.data') }}
                  </b-button>

                  <b-form-select
                    v-model="row.item.status"
                    :options="order_status"
                    @change.native="updOrder(row.item)"
                  ></b-form-select>
                </b-button-group>
              </template>
              <template #row-details="row">
                <b-card-text class="card-subtitle">
                  <!-- TABLE -->
                  <table class="table table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <ul v-for="oi in row.item.order_items" :key="oi.collect_at">
                            <li>
                              {{ oi.collect_at | deDateTime }}
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul
                            class="product_names"
                            v-for="pn in row.item.product_names.split(',')"
                            :key="pn.id"
                          >
                            <li>
                              {{ pn }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-text>
                <b-card title="User Comment" class="card-title">
                  <b-card-text class="card-subtitle">
                    {{ row.item.user_comment }}
                  </b-card-text>
                  <b-button
                    variant="outline-info"
                    class="mt-1"
                    size="sm"
                    @click="row.toggleDetails"
                    >{{ $t('btnOptions.hide') }}</b-button
                  >
                </b-card>
              </template>
            </b-table>
            <!-- Info modal -->
            <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
              <pre>{{ infoModal.content }}</pre>
            </b-modal>
            <template>
              <div class="overflow-auto">
                <b-pagination
                  align="center"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
                <p class="mt-3">{{ $t('pagination.page') }} {{ currentPage }}</p>
              </div>
            </template>
          </template>
        </div>
      </ApolloQuery>
    </b-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import QUERY_STATUS from '@/graphql/OrderStatus.gql';
import UPD_STATUS from '@/graphql/UpdOrderStatus.gql';

export default {
  apollo: {
    order_status: {
      query: QUERY_STATUS,
    },
  },
  data() {
    return {
      order_status_selected: 'none',
      order_status: [],
      perPage: 10,
      currentPage: 1,
      sortBy: 'order_items[0].collect_at',
      showOrderedAtActive: '',
      showCreatedAtActive: '',
      dataForShowing: [],
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      product_names: {
        key: 'product_names',
        label: 'Produkt',
        sortable: true,
      },
      fields: [
        {
          key: 'order_items[0].collect_at',
          label: 'Bestellt für',
          sortable: true,
          formatter: (value) => {
            if (value) {
              value;
            } else {
              return 'Jetzt';
            }
            return (
              dayjs(value)
                .locale('de')
                .format('DD/MMMM/YYYY - HH:mm') + 'Uhr'
            );
          },
        },
        { key: 'product_names', label: 'Produkt' },
        { key: 'address_city', label: 'Stadt' },
        { key: 'address_line_1', label: 'Adresse' },
        { key: 'details', label: 'Details' },
      ],
    };
  },
  computed: {
    rows() {
      return this.dataForShowing.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    sortByDate(arr) {
      console.log(arr);
      return arr.sort((a, b) => b.collect_at - a.collect_at);
    },
  },
  methods: {
    onOrderAdded(previousResult, { subscriptionData }) {
      // console.log(data.orders)
      // console.log(previousResult);
      // console.log(subscriptionData.data.orders[0].order_items[0]);
      // console.log(subscriptionData.data.orders[0]);
      // console.log(subscriptionData.data.orders[0].product_names.split(','));
      this.dataForShowing = subscriptionData.data.orders;
      // const newResult = { orders: [...subscriptionData.data.orders] };
      // console.log(newResult);
      // return {orders: [{id: 1, user_id: 2, __typename: "orders", created_at: "sdgdsgsg"}]};
      // TODO: Make subscription to fetch only fresh stuff to make it scale better.
      return subscriptionData.data;
    },
    updOrder(o) {
      this.$apollo.mutate({
        mutation: UPD_STATUS,
        variables: {
          id: o.id,
          status: o.status,
        },
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
  },
};
</script>

<style>
.card-title {
  color: black;
  position: relative;
  padding-bottom: 10px;
}
.card-title:not(:last-child)::after {
  content: '';
  position: absolute;
  background-color: gray;
  width: 15%;
  height: 1px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.card-subtitle {
  color: black;
  padding-bottom: 15px;
  margin-top: 0.625rem;
}
.product_names {
  list-style-type: none;
}
.cart-plus {
  border: 1px #62ae41 solid;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin-right: 6px;
}
</style>
