<template>
  <div class="container p-4 mb-4">
    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
      {{ error }}
    </b-alert>
    <b-container class="my-4 py-2 mtk-shadow bg-white" fixed>
      <!-- Apollo watched Graphql query -->
      <ApolloQuery :query="require('@/graphql/ListProducts.gql')">
        <template slot-scope="{ result: { loading, error, data } }">
          <b-col class="ml-0 mx-auto p-3" style="width: 450px;">
            <b-form-group
              :label="$t('searchInput.search')"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  :placeholder="$t('searchInput.searchType')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- Loading & Error -->
          <div v-if="loading" class="loading apollo">{{ $t('btnOptions.loading') }}</div>
          <div v-else-if="error" class="error apollo">{{ $t('btnOptions.error') }}</div>

          <!-- Result -->
          <div v-else-if="data" class="result apollo">
            <b-table
              class="table-mobile"
              fixed
              :items="data.products"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              striped
            >
              <template #cell(details)="row">
                <b-button-group class="flex-wrap">
                  <b-button variant="outline-info" size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? $t('btnOptions.close') : $t('btnOptions.edit') }}
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="
                      row.item.id
                        ? onDelProduct(row.item)
                        : (data.products = data.products.filter((i) => i !== row.item))
                    "
                  >
                    {{ $t('btnOptions.delete') }}
                  </b-button>
                </b-button-group>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-1">
                    <b-input-group size="sm" :prepend="$t('products.name')">
                      <b-form-input v-model="row.item.name"></b-form-input>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.body')">
                      <b-form-input v-model="row.item.body"></b-form-input>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.allergens')">
                      <b-form-input v-model="row.item.alergens"></b-form-input>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.imageUrl')">
                      <b-form-input v-model="row.item.img_url"></b-form-input>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.onSaleFrom')">
                      <b-form-datepicker
                        id="datepicker1"
                        data-provide="datepicker"
                        v-model="row.item.on_sale_from"
                        :min="min"
                        :max="max"
                        locale="de"
                      ></b-form-datepicker>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.onSaleTo')">
                      <b-form-datepicker
                        id="datepicker2"
                        data-provide="datepicker"
                        v-model="row.item.on_sale_to"
                        :min="min"
                        :max="max"
                        locale="de"
                      ></b-form-datepicker>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.price')" append="€">
                      <b-form-input
                        style="max-width: 100px;"
                        v-model="row.item.price"
                      ></b-form-input>
                    </b-input-group>
                  </b-row>
                  <b-row class="my-1">
                    <b-input-group size="sm" :prepend="$t('products.category')">
                      <b-form-radio-group
                        class="display"
                        v-model="row.item.category_id"
                        :options="categories"
                        name="cat"
                        button-variant="outline-secondary"
                        size="sm"
                        buttons
                      ></b-form-radio-group>
                    </b-input-group>
                  </b-row>
                  <b-button
                    variant="outline-info"
                    class="mt-1"
                    size="sm"
                    @click="onSaveProduct(row.item)"
                    >{{ $t('btnOptions.save') }}</b-button
                  >
                </b-card>
              </template>
            </b-table>
            <b-button-group class="mt-4">
              <b-button variant="outline-success" @click="data.products.push(newproduct())">
                {{ $t('btnOptions.addProduct') }}
              </b-button>
            </b-button-group>
          </div>

          <!-- No result -->
          <div v-else class="no-result apollo">{{ $t('btnOptions.noResult') }}</div>
        </template>
      </ApolloQuery>
    </b-container>
  </div>
</template>

<script>
import QUERY_PRODUCTS from '@/graphql/ListProducts.gql';
import QUERY_CAT_RADIO from '@/graphql/ListCatForRadio.gql';
import DEL_PRODUCT from '@/graphql/DelProduct.gql';
import INS_PRODUCT from '@/graphql/InsertProduct.gql';
import UPD_PRODUCT from '@/graphql/UpdateProduct.gql';

export default {
  apollo: {
    categories: {
      query: QUERY_CAT_RADIO,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setDate(minDate.getDate() - 2);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'de', text: 'German (de)' },
      ],
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren',
        },
      },
      value: '',
      min: minDate,
      max: maxDate,
      data: [],
      layout: {},
      fields: [
        { key: 'name', label: 'Produkt' },
        { key: 'body', label: 'Beschreibung' },
        { key: 'details', label: 'Details' },
      ],
      perPage: 3,
      currentPage: 1,
      error: null,
      showDismissibleAlert: false,
      filter: null,
      filterOn: [],
    };
  },
  methods: {
    newproduct() {
      return { name: 'New', body: 'Product' };
    },
    onDelProduct(product) {
      if (product.id) {
        this.$apollo
          .mutate({
            mutation: DEL_PRODUCT,
            variables: {
              id: product.id,
            },
            update: (store, { data: { delete_products_by_pk } }) => {
              const data = store.readQuery({ query: QUERY_PRODUCTS });
              const index = data.products.findIndex((e) => e.id === delete_products_by_pk.id);
              if (index > -1) {
                data.products.splice(index, 1);
              }
              store.writeQuery({ query: QUERY_PRODUCTS, data });
            },
          })
          .catch((error) => {
            this.error = JSON.stringify(error.message);
            this.showDismissibleAlert = true;
            window.scrollTo(0, 0);
          });
      }
    },
    onSaveProduct(product) {
      if (!product.id) {
        this.$apollo.mutate({
          mutation: INS_PRODUCT,
          variables: {
            name: product.name,
            body: product.body,
            alergens: product.alergens,
            img_url: product.img_url,
            on_sale_from: product.on_sale_from,
            on_sale_to: product.on_sale_to,
            price: product.price,
            cat_id: product.category_id,
          },
          update: (store, { data: { insert_products_one } }) => {
            const data = store.readQuery({ query: QUERY_PRODUCTS });
            data.products[data.products.length - 1] = insert_products_one;
            store.writeQuery({ query: QUERY_PRODUCTS, data });
          },
        });
      } else {
        this.$apollo
          .mutate({
            mutation: UPD_PRODUCT,
            variables: {
              id: product.id,
              name: product.name,
              price: product.price,
              body: product.body,
              alergens: product.alergens,
              img_url: product.img_url,
              on_sale_from: product.on_sale_from,
              on_sale_to: product.on_sale_to,
              cat_id: product.category_id,
            },
          })
          .catch((error) => {
            this.error = JSON.stringify(error.message);
            this.showDismissibleAlert = true;
            window.scrollTo(0, 0);
          });
      }
      product._showDetails = false;
    },
  },
};
</script>
<style scoped>
.display {
  display: contents;
}

/* Responsive design */
@media only screen and (max-width: 430px) {
  .table-mobile {
    font-size: 12px;
  }
}
</style>
