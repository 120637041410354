<template>
  <div class="bgr shop">
    <!-- <awesome-input :age.sync="age" :address.sync="address" :name.sync="firstName" />
    <p>Name: {{ firstName }}</p>
    <p>Age: {{ age }}</p>
    <p>Address: {{ address }}</p> -->
    <b-container>
      <b-button-group class="mt-2 btns flex-wrap">
        <b-button :to="{ name: 'NameCat' }" variant="outline-success">{{ $t('nav.nameCategories') }}</b-button>
        <!-- <b-button :to="{ name: 'DayCat' }" variant="outline-info">Day Categories</b-button> -->
        <b-button :to="{ name: 'ShopProducts' }" variant="outline-info">{{ $t('nav.products') }}</b-button>
        <b-button :to="{ name: 'ShopOrders' }" variant="outline-primary">{{ $t('nav.orders') }}</b-button>
        <b-button :to="{ name: 'Messages' }" variant="outline-primary">{{ $t('nav.messages') }}</b-button>
      </b-button-group>
    </b-container>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      firstName: 'Krasimirski',
      address: '',
      age: 40,
    };
  },
};
</script>

<style>
.shop {
  height: 100vh;
}
.btns {
  background-color: azure;
  border-radius: 5px;
}
</style>